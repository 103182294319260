class CommonValues {
  public static readonly Logout = (navigate: any) => {
    this.ClearAllValues();

    navigate("/");
  };

  public static readonly GetToken = () => {
    return localStorage.getItem("igtoken");
  };

  public static readonly GetTenantId = () => {
    return localStorage.getItem("tenantId");
  };

  public static readonly SetToken = (token: any) => {
    localStorage.setItem("igtoken", token);
  };

  public static readonly SetTenantId = (tenantId: any) => {
    localStorage.setItem("tenantId", tenantId);
  };

  public static readonly ClearAllValues = () => {
    localStorage.removeItem("igtoken");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("BusinessName");
    localStorage.removeItem("RoleId");
    localStorage.removeItem("AvatarName");
    localStorage.removeItem("UserName");
    localStorage.removeItem("SocialLogin");
    localStorage.removeItem("Embeded");
    localStorage.removeItem("NowCertsAgencyId");
    localStorage.removeItem("IsTalkToAMSEnabled");
  };
}

export default CommonValues;
