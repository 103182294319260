import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import Header from "../../Common/Header";
import HomeSidebar from "../../Common/HomeSidebar";
import CommonValues from "../../Common/Utils";
import SupportLoader from "../../CommonControls/SupportLoader";

export default function PolicyComparision() {
  const [firstSelectedFile, setFirstSelectedFile] = useState<File>(
    new File([], "")
  );
  const [secondSelectedFile, setSecondSelectedFile] = useState<File>(
    new File([], "")
  );
  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [firstFileFormatErrorMessage, setFirstFileFormatErrorMessage] =
    useState("");
  const [secondFileFormatErrorMessage, setSecondFileFormatErrorMessage] =
    useState("");
  const [comparedResponse, setComparedResponse] = useState("");
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const [generateProposalLoading, setGenerateProposalLoading] = useState(false);
  const [comparePoliciesLoading, setComparePoliciesLoading] = useState(false);
  const [compareLimitsLoading, setCompareLimitsLoading] = useState(false);
  const [compareCoverageCodesLoading, setCompareCoverageCodesLoading] =
    useState(false);
  const [summarizeSelected, setSummarizeSelected] = useState(true);
  const [comparePoliciesSelected, setComparePoliciesSelected] = useState(false);
  const [summarizePolicyButtonSelected, setSummarizePolicyButtonSelected] =
    useState(false);
  const [generateProposalButtonSelected, setGenerateProposalButtonSelected] =
    useState(false);
  const [comparePoliciesButtonSelected, setComparePoliciesButtonSelected] =
    useState(false);
  const [compareLimitsButtonSelected, setCompareLimitsButtonSelected] =
    useState(false);
  const [compareCoveragesButtonSelected, setCompareCoveragesButtonSelected] =
    useState(false);
  const [responseStreaming, setResponseStreaming] = useState(false);
  const navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  useEffect(() => {
    if (comparedResponse.length > 1) {
      scrollToBottomForAnswer();
    }
  }, [comparedResponse]);

  const scrollToBottomForAnswer = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-answer-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };

  const toast: any = useRef("");

  const onFirstFileSelect = (e: any) => {
    setFirstFileFormatErrorMessage("");
    if (e.target?.files[0] && e.target.files[0].type === "application/pdf") {
      setFirstSelectedFile(e.target.files[0]);
      setFileName1(e.target.files[0].name);
    } else {
      setFirstFileFormatErrorMessage("Please select pdf files.");
    }
    // e.preventDefault();
  };
  const onSecondFileSelect = (e: any) => {
    setSecondFileFormatErrorMessage("");
    if (e.target?.files[0] && e.target.files[0].type === "application/pdf") {
      setSecondSelectedFile(e.target.files[0]);
      setFileName2(e.target.files[0].name);
    } else {
      setSecondFileFormatErrorMessage("Please select pdf files.");
    }
    // e.preventDefault();
  };
  const onCloseIconClick = (fileName: string, fileSlot: number) => {
    if (fileSlot == 1 && fileName === fileName1) {
      setFirstSelectedFile(new File([], ""));
      setFileName1("");
    } else if (fileSlot == 2 && fileName === fileName2) {
      setSecondSelectedFile(new File([], ""));
      setFileName2("");
    }
  };
  const onBackButton = () => {
    setFirstSelectedFile(new File([], ""));
    setFileName1("");
    setSecondSelectedFile(new File([], ""));
    setFileName2("");
    setComparedResponse("");
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
  };
  const validatePolicyCompareRequest = (prompt: number): boolean => {
    let isValid: boolean = false;
    console.log("firstSelectedFile size = " + firstSelectedFile.size);
    console.log("secondSelectedFile size = " + secondSelectedFile.size);
    if (prompt === 1 && firstSelectedFile.size === 0) {
      setSummarizeLoading(false);
      setSummarizePolicyButtonSelected(false);
      toast.current.show({
        severity: "warn",
        detail: "Please select policy to summarize.",
        life: 5000,
      });
    } else if (prompt === 5 && firstSelectedFile.size === 0) {
      setGenerateProposalLoading(false);
      setGenerateProposalButtonSelected(false);
      toast.current.show({
        severity: "warn",
        detail: "Please select policy to get general proposal.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      firstSelectedFile.size === 0 &&
      secondSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "warn",
        detail: "Please select first and second policy for comparison.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      firstSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "warn",
        detail: "Please select first policy for comparison.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      secondSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "warn",
        detail: "Please select second policy for comparison.",
        life: 5000,
      });
    } else {
      isValid = true;
    }
    return isValid;
  };
  const getPolicyComparePrompt = (prompt: number) => {
    let question = "";
    if (prompt === 1) {
      question = `Provide a detailed summary of ${firstSelectedFile.name}. Extract all the fields with their values, including limits, coverages, and premium, and present them in a single table.`;
    } else if (prompt === 2) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} and provide detailed comparison including limits, coverages and premium in a tabular format. Additionally, offer a brief analysis on which policy might be more suitable for someone prioritizing immediate coverage for pre-existing conditions versus cost savings.`;
    } else if (prompt === 3) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} for limits and provide a comparison of limits in a tabular format.`;
    } else if (prompt === 4) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} for coverages and provide a comparison of coverages in a tabular format.`;
    } else if (prompt === 5) {
      question = `You are smart insurance agent and you need to create Commercial Insurance Proposal for your insured from ${firstSelectedFile.name}. Add details like Premium Summary, Quote Details, Premium Schedule, Payment Installment Schedule, Important Notices and Proposal Terms & Conditions`;
    }
    return question;
  };
  const onCompareFileClick = async (prompt: number) => {
    if (!validatePolicyCompareRequest(prompt)) {
      return;
    }
    let question = getPolicyComparePrompt(prompt);
    const FormData = require("form-data");
    const data = new FormData();
    data.append("FirstFormFile", firstSelectedFile);
    data.append("SecondFormFile", secondSelectedFile);
    data.append("Question", question);
    const token = CommonValues.GetToken();
    setComparedResponse("");

    try {
      const ctrl = new AbortController();
      await fetchEventSource(
        `${process.env.REACT_APP_PolicyAgent_Url}/process_files`,
        {
          method: "POST",
          headers: {
            "X-Api-Key":
              "NWJlNWNmZmItZmViNi00ZWNhLTgwNzQtZWI2YzAzZTA5NDZkXzNjMDZmMGZlLTI4MTgtNDQ3NS1hMGRlLTU4OWFkNDE4YWJiMQ",
            Authorization: `Bearer ${token}`,
          },
          body: data,
          signal: ctrl.signal,
          onopen(response: any): Promise<void> {
            if (response) {
              if (
                response.ok &&
                response.headers.get("content-type") === "text/event-stream"
              ) {
              } else if (response.status === 401) {
                CommonValues.Logout(navigate);
              }
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let data = event.data;

            if (data !== "") {
              setResponseStreaming(true);
              setSummarizeLoading(false);
              setGenerateProposalLoading(false);
              setComparePoliciesLoading(false);
              setCompareLimitsLoading(false);
              setCompareCoverageCodesLoading(false);
              setComparedResponse((previous) => previous + data);
            } else {
              setComparedResponse((previous) => `${previous}\n`);
            }

            scrollToBottomForAnswer();
          },
          onclose() {
            console.log("Connection closed by the server");
            setResponseStreaming(false);
          },
          onerror(error: any) {
            setResponseStreaming(false);
            setSummarizeLoading(false);
            setGenerateProposalLoading(false);
            setComparePoliciesLoading(false);
            setCompareLimitsLoading(false);
            setCompareCoverageCodesLoading(false);
          },
        }
      );
    } catch (error: any) {}
  };
  const onSummarizePolicyClick = () => {
    setSummarizeLoading(true);
    setSummarizePolicyButtonSelected(true);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(1);
  };

  const onGenerateProposalClick = () => {
    setGenerateProposalLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(true);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(5);
  };

  const onComparePoliciesClick = () => {
    setComparePoliciesLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(true);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(2);
  };
  const onCompareLimitsClick = () => {
    setCompareLimitsLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(true);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(3);
  };
  const onCompareCoverageCodesClick = () => {
    setCompareCoverageCodesLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(true);
    onCompareFileClick(4);
  };
  return (
    <div className="widget-landing-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper container-fluid d-flex justify-content-center p-0">
        <div className="row main-content mx-auto  w-100 ">
          <div className="col support-bot-sidebar-main-div">
            <HomeSidebar />
          </div>
          <div className="col d-flex justify-content-center">
            <div className=" d-flex align-items-center flex-column container-fluid w-100 ">
              <div className="row mx-auto w-100 container d-flex justify-content-center">
                {" "}
                <div className="col-3 mt-4"></div>
                <div className="col-6 d-flex align-items-center mt-4">
                  <input
                    type="checkbox"
                    value=""
                    checked={summarizeSelected}
                    onChange={() => {
                      setFirstFileFormatErrorMessage("");
                      setSecondFileFormatErrorMessage("");
                      setSummarizeSelected(!summarizeSelected);
                      setComparePoliciesSelected(false);
                    }}
                    className="summary-compare-selection"
                  />
                  <span className="d-block h5 mx-2 mt-1">Summarize Policy</span>

                  <div className="compare-selection d-flex align-items-center ">
                    <input
                      type="checkbox"
                      value=""
                      checked={comparePoliciesSelected}
                      onChange={() => {
                        setFirstFileFormatErrorMessage("");
                        setSecondFileFormatErrorMessage("");
                        setSummarizeSelected(false);
                        setComparePoliciesSelected(!comparePoliciesSelected);
                      }}
                      className="summary-compare-selection"
                    />
                    <span className="d-block h5 mx-2 mt-1">
                      Compare Policies
                    </span>
                  </div>
                </div>
                <div className="col-3 mt-4"></div>
                <div className="col-2 mb-2 mt-3"></div>
                <div
                  className={`col-${
                    comparePoliciesSelected ? "4" : "8"
                  } form-group text-center mb-2 mt-3 choose-file-box`}
                >
                  <div className="file-upload position-relative">
                    <span className="d-block h5">
                      {comparePoliciesSelected ? "First" : ""} Policy
                    </span>

                    <input
                      type="file"
                      className="custom-file-input"
                      id="firstFileInput"
                      onChange={onFirstFileSelect}
                      name="fileData"
                      accept=".pdf"
                    />
                    <label
                      htmlFor="firstFileInput"
                      className="custom-file-label2"
                    >
                      Choose File
                    </label>
                    <p className="text-danger">{firstFileFormatErrorMessage}</p>
                    {fileName1.length > 0 ? (
                      <span className="file-name">
                        {fileName1}{" "}
                        <i
                          className="fa fa-close ms-2 show-cursor "
                          aria-hidden="true"
                          title="Close"
                          onClick={() => onCloseIconClick(fileName1, 1)}
                        ></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                {comparePoliciesSelected && (
                  <div className="col-4 form-group mb-2 text-center mt-3 choose-file-box second-choose-file-box">
                    <div className="file-upload">
                      <span className="d-block h5">Second Policy</span>

                      <input
                        type="file"
                        className="custom-file-input"
                        id="secondFileInput"
                        onChange={onSecondFileSelect}
                        name="fileData2"
                        accept=".pdf"
                        // multiple
                      />
                      <label
                        htmlFor="secondFileInput"
                        className="custom-file-label2 "
                      >
                        Choose File
                      </label>
                      <p className="text-danger">
                        {secondFileFormatErrorMessage}
                      </p>
                      {fileName2.length > 0 ? (
                        <span className="file-name ">
                          {fileName2}{" "}
                          <i
                            className="fa fa-close ms-2 show-cursor "
                            aria-hidden="true"
                            title="Close"
                            onClick={() => onCloseIconClick(fileName2, 2)}
                          ></i>
                        </span>
                      ) : null}
                    </div>
                    {/* <span className="text-danger text-12 ">{fileError}</span> */}
                  </div>
                )}
                <div className="col-2 mb-2 mt-3"></div>
                {summarizeSelected && (
                  <>
                    <div className="col-md-3"></div>
                    <div className="col-md-3 upload-btn mt-4 text-center">
                      {summarizeLoading ? (
                        <div className="d-flex justify-content-center">
                          <SupportLoader />
                        </div>
                      ) : (
                        <input
                          className={`btn ${
                            summarizePolicyButtonSelected
                              ? "submit-btn"
                              : "policy-unselected-btn"
                          }`}
                          type="button"
                          value="Summarize Policy"
                          onClick={onSummarizePolicyClick}
                        />
                      )}
                    </div>
                    <div className="col-md-3 upload-btn mt-4 text-center">
                      {generateProposalLoading ? (
                        <div className="d-flex justify-content-center">
                          <SupportLoader />
                        </div>
                      ) : (
                        <input
                          className={`btn ${
                            generateProposalButtonSelected
                              ? "submit-btn"
                              : "policy-unselected-btn"
                          }`}
                          type="button"
                          value="Generate Proposal"
                          onClick={onGenerateProposalClick}
                        />
                      )}
                    </div>
                    <div className="col-md-3"></div>
                  </>
                )}
                {comparePoliciesSelected && (
                  <>
                    <div className="col-md-2 mt-4"></div>
                    <div className="col-md-8 mt-4">
                      <div className="row">
                        <div className="col-md-4 text-start p-0">
                          {compareLimitsLoading ? (
                            <div className="d-flex justify-content-start">
                              <SupportLoader />
                            </div>
                          ) : (
                            <input
                              className={`btn ${
                                compareLimitsButtonSelected
                                  ? "submit-btn"
                                  : "policy-unselected-btn"
                              }`}
                              type="button"
                              value="Compare Limits"
                              onClick={onCompareLimitsClick}
                            />
                          )}
                        </div>
                        <div className="col-md-4 text-center p-0">
                          {compareCoverageCodesLoading ? (
                            <div className="d-flex justify-content-center">
                              <SupportLoader />
                            </div>
                          ) : (
                            <input
                              className={`btn ${
                                compareCoveragesButtonSelected
                                  ? "submit-btn"
                                  : "policy-unselected-btn"
                              }`}
                              type="button"
                              value="Compare Coverage Codes"
                              onClick={onCompareCoverageCodesClick}
                            />
                          )}
                        </div>
                        <div className="col-md-4 text-end p-0">
                          {comparePoliciesLoading ? (
                            <div className="d-flex justify-content-end">
                              <SupportLoader />
                            </div>
                          ) : (
                            <>
                              <input
                                className={`btn ${
                                  comparePoliciesButtonSelected
                                    ? "submit-btn"
                                    : "policy-unselected-btn"
                                }`}
                                type="button"
                                value="Compare Policies"
                                onClick={onComparePoliciesClick}
                                style={{ marginRight: "auto" }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 mt-4"></div>
                  </>
                )}
              </div>

              {comparedResponse.length > 0 ? (
                <div className="row last-answer-block">
                  <div className="col-2"></div>
                  <div className="col-8 my-2 compared-policy-response">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      className="answer-block mt-3"
                    >
                      {comparedResponse}
                    </ReactMarkdown>
                    <div>
                      {responseStreaming && (
                        <div className="d-flex justify-content-center">
                          <SupportLoader />
                        </div>
                      )}
                    </div>
                    <div className="text-center">
                      <input
                        className="btn btn-outline-info"
                        type="button"
                        value="Try Again"
                        onClick={onBackButton}
                      />
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
